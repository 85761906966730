<template>
    <div>
        
        <!-- IMAGEM CABEÇALHO -->
        <v-img class="headerImg"/>

        <!-- CABEÇALHO -->
        <div class="pageHeader">

            <v-btn class="menuButton" icon small color="white" depressed @click="drawer = !drawer" style="margin-right: 10px">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
            
            <v-spacer/>

            <!-- MENU USUARIO -->      
            <v-menu offset-y left transition="slide-y-transition">
                
                <template v-slot:activator="{ on }">
                    <v-btn color="secondary" v-on="on" fab small class="mr-2 mt-2" depressed style="border-radius: 10px">
                        <avatar-component :src="''" :nome="app.nome"/>
                    </v-btn>
                </template>

                <v-list class="menuList">
                
                    <div class="user">
                        <avatar-component :src="''" :nome="app.nome"/>
                        <div style="width: 100%">
                            <span style="font-size: 15px">{{app.nome}}</span> <br/>
                            <span style="font-size: 12px">{{app.email}}</span>
                        </div>
                    </div>

                    <v-divider/>

                    <!-- <v-list-item v-if="app.temaEscuro" @click="TemaEscuro()">
                        <v-list-item-icon><v-icon>mdi-invert-colors-off</v-icon></v-list-item-icon>
                        <v-list-item-title>Tema Claro</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item v-else @click="TemaEscuro()">
                        <v-list-item-icon><v-icon>mdi-invert-colors</v-icon></v-list-item-icon>
                        <v-list-item-title>Tema Escuro</v-list-item-title>
                    </v-list-item> -->

                    <v-list-item @click="dialogAlterarSenha = true">
                        <v-list-item-icon><v-icon>mdi-lock-reset</v-icon></v-list-item-icon>
                        <v-list-item-title>Alterar Senha</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="Logout()">
                        <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                        <v-list-item-title>Sair</v-list-item-title>
                    </v-list-item>

                </v-list>

            </v-menu>

            <!-- ACOES LISTA -->
            <div class="actions">
                
                <div style="color: white; font-size: 20px">
                    <v-icon color="white" style="margin-right: 10px; font-size: 28px !important">{{icone}}</v-icon>
                    <span>{{titulo}}</span> <br/>
                </div>

                <v-spacer/>

                <slot name="acoes"/>

            </div>
        
        </div>

        <!-- CONTEUDO -->
        <div class="pageContent">
            <v-card>
                <slot/>
            </v-card>
        </div>

        <!-- MENU LATERAL -->
        <v-navigation-drawer class="drawerMenu" fixed v-model="drawer" temporary :width=" $vuetify.breakpoint.smAndDown ? '70vw' : '300px'" dark>
        
        <div class="menuEmpresaLogo">
            <a href="/home">
            <img src="@/assets/images/logo.png">
            </a>
        </div>

        <v-subheader class="ml-2 mt-1">MENU</v-subheader>

        <v-divider/>
        
        <v-list nav dense class="pa-0 navDrawerMenu">
            
            <v-list-item to="/home" style="padding-left: 10px; border-radius: 10px">
                <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
                <v-list-item-title>Home</v-list-item-title>
            </v-list-item>

            <div v-for="(item, index) in geralMenuButtons" :key="index" style="padding: 2px 10px 2px 10px">
                <v-list-item :to="item.path" :disabled="item.restrito" style="padding-left: 10px; border-radius: 10px">
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-icon><v-icon>{{item.icon}}</v-icon></v-list-item-icon>
                </v-list-item>
            </div>

        </v-list>
        
        <template v-slot:append>
            
            <v-list-item @click="Logout()" style="padding-left: 30px">
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>

            <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 5px">
                <span style="color: white; font-size: 12px"> Versão - {{GetAppVersion()}} </span>
            </div>

        </template>

        </v-navigation-drawer>

        <alterar-senha v-model="dialogAlterarSenha" @fechou="dialogAlterarSenha = false"/>

    </div>
</template>

<script lang="ts">
import { PageBase } from '@/core/models/';
import { UsuarioService } from '@/core/services';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MasterPage extends PageBase{
    @Prop() public icone!: string;
    @Prop() public titulo!: string;

    drawer: boolean = false;
    dialogAlterarSenha: boolean = false;

    calculadorasMenuButtons: any[] = [];
    geralMenuButtons: any[] = [];
    operacaoMenuButtons: any[] = [];
    relatorioMenuButtons: any[] = [];
    configMenuButtons: any[] = [];

    created() {
        this.$vuetify.theme.dark = this.app.temaEscuro;
        this.CarregarMenu();
    }

    CarregarMenu(){
        this.geralMenuButtons = [
            { name:'Assunto / Termos', icon:'mdi-newspaper', path:'/assunto', restrito: false },
            { name:'Veículo', icon:'mdi-book-open-page-variant-outline', path:'/veiculo', restrito: false },
            { name:'Usuário', icon:'mdi-account', path:'/usuario', restrito: false }
        ];
    }

    TemaEscuro(){
        const usuarioService = new UsuarioService();
        usuarioService.MudarTema(this.app.usuarioId).then(
            res => super.MudarTema(res.data),
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    Logout() {
        localStorage.clear();
        this.$vuetify.theme.dark = false;
        this.$router.push({name: 'Login'});
    }

}
</script>

<style lang="scss">

    .drawerMenu{
        background: linear-gradient(160deg, rgba(193, 68, 40, 0.95), rgba(112, 31, 12, 0.85)) !important;
    }

    .navDrawerMenu{
        .v-list-item--active {
            color: var(--v-menuActive-base);
        }
    }

    .menuEmpresaLogo{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 0px 25px 0px;
        background-color: white;
        border-radius: 0px 0px 25px 25px;
        opacity: 1 !important;
        img{        
            max-height: 150px;
        }
    }

    .menuList{
        width: 300px;
        .user{
            margin: 0px 0px 10px 10px;
            display: inline-flex;
            padding: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .avatar{
                margin-right: 10px !important;
            }
        }
    }

</style>
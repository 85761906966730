import Vue from 'vue';

//SISTEMA
import AlterarSenha from './shared/AlterarSenha.vue';
import Avatar from './shared/Avatar.vue';
import MasterPage from './shared/MasterPage.vue';
import TextSearch from './shared/TextSearch.vue';
import CadastroPage from './shared/CadastroPage.vue';
import ListaFooter from './shared/ListaFooter.vue';

Vue.component("alterar-senha", AlterarSenha);
Vue.component("avatar-component", Avatar);
Vue.component("master-page", MasterPage);
Vue.component("text-search", TextSearch);
Vue.component("cadastro-page", CadastroPage);
Vue.component("lista-footer", ListaFooter);

//GERAL
import CadastroAssunto from './geral/assunto/Cadastro.vue'
import CadastroCliente from './geral/cliente/Cadastro.vue'
import CadastroUsuario from './geral/usuario/Cadastro.vue'
import CadastroVeiculo from './geral/veiculo/Cadastro.vue'

Vue.component("cadastro-assunto", CadastroAssunto);
Vue.component("cadastro-cliente", CadastroCliente);
Vue.component("cadastro-usuario", CadastroUsuario);
Vue.component("cadastro-veiculo", CadastroVeiculo);
import { PageBase } from "./PageBase";

export class ListBase extends PageBase{

    //GRID ITEMS
    public sheet: boolean = false;
    public listaSheet: boolean = false;
    public lista: any[] = [];
    public total: number = 0;
    public options: any = {
        itemsPerPage: 15
    }
    public search: string = '';    
    public dialogCadastro: boolean = false;
    public page: number = 1;
    public pageCount: number = 1;
    public itemsPage: any[] = [
        {text: "15", value: 15},
        {text: "25", value: 25},
        {text: "50", value: 50}
    ];

}
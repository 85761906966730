<template>
    <cadastro-page :dialog="dialog" :titulo="item.id > 0 ? 'Editar Veículo' : 'Criar Veículo'" :valid="valid" :overlay="loading" @fechar="Close()" @salvar="Salvar()">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card flat>
                <v-card-text class="mt-2">
                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <v-text-field type="number" v-model.number="item.knewingId" label="Knewing Id" :rules="numberFieldRules" dense outlined/>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field v-model="item.nome" label="Nome" :rules="fieldRules" :counter="60" maxlength="60" dense outlined/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
    </cadastro-page>
</template>

<script lang="ts">
import { CrudBase, Veiculo } from '@/core/models';
import { VeiculoService } from '@/core/services';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { createPatch } from 'rfc6902';
import { Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class CadastroVeiculo extends CrudBase{
    @Prop() private value!: string;
    @Prop() item!: Veiculo;

    itemOriginal!: Veiculo;
    service: VeiculoService = new VeiculoService();
    $refs!: {
        form: HTMLFormElement,
        file1: HTMLFormElement
    }

    foto: any = null;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = JSON.parse(JSON.stringify(this.item));
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = createPatch(this.itemOriginal, this.item);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
</script>

<style lang="scss">
    .v-card{
        border-radius: 5px !important;
    }
</style>
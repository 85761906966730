<template>
    <cadastro-page :dialog="dialog" :titulo="item.id > 0 ? 'Editar Cliente' : 'Criar Cliente'" :valid="valid" :overlay="loading" @fechar="Close()" @salvar="Salvar()">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-row dense>
                <v-col id="userMedia" cols="12" sm="12" md="3" lg="2" :style="$vuetify.breakpoint.smAndDown ? 'display: flex; justify-content: center' : 'display: flex; flex-direction: column; justify-content: flex-start; align-items: center'">
                    <v-card flat class="ma-1">
                        <v-card-title>
                            <span> Foto </span>
                        </v-card-title>
                        <v-card-text style="display: flex; justify-content: center; align-items: center">
                            <v-card width="170px" height="170px">
                                <div v-if="!item.foto" style="width: 100%; height: 100%" >
                                    <label title="Selecionar item" for="file" style="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; cursor: pointer">
                                        <img style="width: 100px" src="@/assets/images/imageInput.png" alt="input">
                                    </label>
                                    <input @change="LoadImage()" accept="image/png, image/jpeg, image/jpg" type="file" name="file" id="file" ref="file1" style="visibility: hidden"/>
                                </div>
                                <div v-else style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
                                    <v-btn icon style="position: absolute; top: 2%; right: 2%; z-index: 10; color: red" @click="RemoveImage()"><v-icon>mdi-close</v-icon></v-btn>
                                    <v-img :src="`data:image/jpeg;base64,${item.foto}`" contain width="150px" height="150px"/>
                                </div>
                            </v-card>
                        </v-card-text>
                        <v-card-subtitle style="text-align: center">
                            <span>Define a foto que será utilizada pelo usuário.</span> <br/>
                            <span style="color: red">*.png, *.jpg e *.jpeg.</span>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
                <v-spacer/>
                <v-col cols="12" sm="12" md="9" lg="10">
                    <v-card flat>
                        <v-card-text class="mt-2">
                            <v-row dense>
                                <v-col cols="12" sm="12">
                                    <v-text-field v-model="item.nome" label="Nome" :rules="fieldRules" :counter="60" maxlength="60" dense outlined/>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field v-model.number="item.projetoId" type="number" label="Projeto_Id" :rules="numberGtZeroFieldRules" dense outlined/>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-text-field v-model="item.token" label="Token" :rules="fieldRules" dense outlined/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
    </cadastro-page>
</template>

<script lang="ts">
import { Cliente, CrudBase } from '@/core/models';
import { ClienteService } from '@/core/services';
import { ArquivoService } from '@/core/services/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { createPatch } from 'rfc6902';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class CadastroCliente extends CrudBase{
    @Prop() private value!: string;
    @Prop() item!: Cliente;

    itemOriginal!: Cliente;
    service: ClienteService = new ClienteService();
    $refs!: {
        form: HTMLFormElement,
        file1: HTMLFormElement
    }

    foto: any = null;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = JSON.parse(JSON.stringify(this.item));
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    Carregar(){
        
    }

    async LoadImage(){
        this.foto = this.$refs.file1.files[0];
        if (!this.foto)
            return;
        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.foto);
        this.item.foto = dados.replace(/^[^,]*,/, "");
    }
    RemoveImage(){
        this.item.foto = null;
        this.foto = null;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = createPatch(this.itemOriginal, this.item);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
</script>

<style lang="scss">
    .v-card{
        border-radius: 5px !important;
    }
</style>
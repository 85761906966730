export class Veiculo implements Shared.IEntity{

    id: number = 0;
    knewingId: number = 0;
    nome: string = "";
    tipo?: string;
    tier?: number;
    url?: string;
    pais?: string;
    estado?: string;
    ativo: boolean = true;

    constructor(model?: Veiculo){

        if(!model)
            return;

        this.id = model.id;
        this.knewingId = model.knewingId;
        this.nome = model.nome;
        this.tipo = model.tipo;
        this.tier = model.tier;
        this.url = model.url;
        this.pais = model.pais;
        this.estado = model.estado;
        this.ativo = model.ativo;
    }
}
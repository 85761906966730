export class Cliente implements Shared.IEntity{

    id: number = 0;
    nome: string = "";
    projetoId: number = 0;
    token: string = "";
    foto: any = null;
    ativo: boolean = true;

    constructor(model?: Cliente){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.projetoId = model.projetoId;
        this.token = model.token;
        this.foto = model.foto;
        this.ativo = model.ativo;
    }
}
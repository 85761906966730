<template>    
    <div class="mt-4 mx-3">
        <v-row id="page-control" class="mt-2 mx-4" style="display: flex; justify-content: flex-end; align-items: center;" dense>
            <v-col id="per-page" :class="!$vuetify.breakpoint.mobile ? 'mx-2' : ''" cols="12" sm="4" md="3" lg="2">
                <v-select flat label="Itens por página" v-model="itemsPerPageNumb" :items="itemsPage" item-value="value" item-text="text" :style="!$vuetify.breakpoint.mobile ? '' : 'margin-right: 0'" hide-details outlined dense/>
            </v-col>
            <v-col id="current-page" :class="!$vuetify.breakpoint.mobile ? 'mx-2' : ''" cols="12" sm="auto" md="auto" lg="auto" class="text-right">
                <span>{{ Totalizador(pagesNumb, itemsPerPageNumb, itemsTotalNumb) }}</span>
            </v-col>
            <v-col id="pagination" :class="!$vuetify.breakpoint.mobile ? 'mx-2' : ''" class="text-center" cols="12" sm="4" md="3" lg="2">
                <v-pagination v-model="pagesNumb" :length="pageCounter" :total-visible="7"/>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
import { ListBase } from '@/core/models';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class ListaHeader extends ListBase{
    @Prop() itemsPerPage!: number;
    @Prop() pages!: number;
    @Prop() itemsTotal!: number;
    @Prop() pageCounter!: number

    itemsPerPageNumb: number = 0;
    pagesNumb: number = 0;
    itemsTotalNumb: number = 0;

    @Watch("itemsPerPage")
    @Watch("pages")
    @Watch("itemsTotal")
    @Watch("pageCounter")
    Atualiza(){
        this.itemsPerPageNumb = this.itemsPerPage;
        this.pagesNumb = this.pages;
        this.itemsTotalNumb = this.itemsTotal;
    }
    
    mounted(){
        this.itemsPerPageNumb = this.itemsPerPage;
        this.pagesNumb = this.pages;
        this.itemsTotalNumb = this.itemsTotal;
    }

    @Watch("itemsPerPageNumb")
    WatchItemsPerPage(){
        this.$emit('itemsPerPage', this.itemsPerPageNumb);
    }

    @Watch("pagesNumb")
    WatchPage(){
        this.$emit('pages', this.pagesNumb);
    }

    public Totalizador(page: number, itemsPerPage: number, total: number){
        let inicio = (page - 1) * itemsPerPage + 1;
        let fim = Math.min(inicio + itemsPerPage - 1, total);
        return `${inicio}-${fim} de ${total}`;
    }
}
</script>
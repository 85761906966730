export class Usuario implements Shared.IEntity{

    id: number = 0;
    nome: string = "";
    email: string = "";
    senha: string = "";
    clienteId?: number;
    cliente!: object;
    master: boolean = false;
    temaEscuro: boolean = false;
    ativo: boolean = true;
    token: string = "";

    constructor(model?: Usuario){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.email = model.email;
        this.senha = model.senha;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.master = model.master;
        this.temaEscuro = model.temaEscuro;
        this.ativo = model.ativo;
        this.token = model.token;
    }
}
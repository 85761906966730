import { Cliente, AssuntoTermo } from "..";

export class Assunto implements Shared.IEntity{

    id: number = 0;
    clienteId: number = 0;
    cliente!: Cliente;
    nome: string = "";

    termos: AssuntoTermo[] = [];

    constructor(model?: Assunto){

        if(!model)
            return;

        this.id = model.id;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.nome = model.nome;

        this.termos = model.termos;
    }
}
import { Assunto } from "..";

export class AssuntoTermo implements Shared.IEntity{

    id: number = 0;
    assuntoId: number = 0;
    assunto!: Assunto;
    nome: string = "";

    constructor(model?: AssuntoTermo){

        if(!model)
            return;

        this.id = model.id;
        this.assuntoId = model.assuntoId;
        this.assunto = model.assunto;
        this.nome = model.nome;
    }
}
import { Vue } from "vue-property-decorator";
import { JSONToCSVConvertor, TableToExcel } from '@/assets/scripts/helper';

export class PageBase extends Vue {
    
    public emailRules = [v => !!v && /^[a-z0-9.-_]+@[a-z0-9.-]+\.[a-z]+$/i.test(v) || 'E-mail precisa ser válido']
    public emailNotRequiredRules = [v => !v || /^[a-z0-9.-_]+@[a-z0-9.-]+\.[a-z]+$/i.test(v) || 'E-mail precisa ser válido']
    public fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    public numberFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v >= 0) || "Campo obrigatório"];
    public numberGtZeroFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v > 0) || "Campo obrigatório"];

    listaSimNao: any = [ 
        {id: true, nome: "Sim"}, 
        {id: false, nome: "Não"}
    ];

    public mskCnpj = process.env.VUE_APP_MSK_CNPJ;
    public mskCpf = process.env.VUE_APP_MSK_CPF;
    public mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    public mskCelular = process.env.VUE_APP_MSK_CELULAR;
    public mskCep = process.env.VUE_APP_MSK_CEP;
    public mskPlaca = process.env.VUE_APP_MSK_PLACA;

    public loading: boolean = false;

    public meses: any[] = [
        { id: 1, nome: "Janeiro" },
        { id: 2, nome: "Fevereiro" },
        { id: 3, nome: "Março" },
        { id: 4, nome: "Abril" },
        { id: 5, nome: "Maio" },
        { id: 6, nome: "Junho" },
        { id: 7, nome: "Julho" },
        { id: 8, nome: "Agosto" },
        { id: 9, nome: "Setembro" },
        { id: 10, nome: "Outubro" },
        { id: 11, nome: "Novembro" },
        { id: 12, nome: "Dezembro" }
    ];

    public sessionApp: any;
    public app: {usuarioId: number, nome: string, email: string, temaEscuro: boolean,  master: boolean} = {
        usuarioId: 0,
        nome: '',
        email: '',
        temaEscuro: false,
        master: false
    };

    constructor(){
        
        super();

        if (!localStorage.sessionApp){
            return;
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.usuarioId = this.sessionApp.dados.usuarioId;
        this.app.nome = this.sessionApp.dados.nome;
        this.app.email = this.sessionApp.dados.email;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.master = this.sessionApp.dados.master;
    }

    ExportarExcel(tabela: string, worksheet?: string){
        TableToExcel(tabela, worksheet);
    }

    JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean){
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
    }

    MudarTema(temaEscuro: boolean){
        this.app.temaEscuro = temaEscuro;
        this.sessionApp.dados.temaEscuro = temaEscuro;
        localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        this.$vuetify.theme.dark = temaEscuro;
    }

    GetAppVersion(){
        const pack = require('@/../package.json');
        return pack.version;
    }
}
<template>
    <v-dialog v-model="dialog" :fullscreen="fullScreen" hide-overlay transition="dialog-bottom-transition" persistent>        
        <v-card style="border-radius: 0px !important; background: var(--v-cadastroBackground-base)">
            <!-- HEADER -->
            <v-card-title class="pa-0 dialogHeader">
                <v-toolbar color="primary" flat dark height="65px">
                    <div style="display: flex; align-items: center; width: 100%; height: 100%">
                        <v-btn :loading="overlay" icon @click="Close()" class="mr-4">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
                        <v-spacer/>
                        <v-toolbar-items>
                            <v-btn :loading="overlay" :disabled="!valid" text @click="Salvar()">
                                Salvar
                            </v-btn>
                        </v-toolbar-items>
                    </div>                    
                    <template v-slot:extension v-if="extensions">
                        <slot name="extension"></slot>
                    </template>
                </v-toolbar>
            </v-card-title>
            <!-- CONTENT -->
            <v-card-text class="mt-5" :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-4'">
                <slot></slot>
            </v-card-text>
        </v-card>
        
        <!-- OVERLAY -->
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

    </v-dialog>    
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class CadastroCotacao extends Vue{
    @Prop({default: true}) fullScreen!: boolean;
    @Prop({default: false}) dialog!: boolean;
    @Prop({default: "Cadastro"}) titulo!: string;
    @Prop({default: false}) extensions!: boolean;
    @Prop({default: true}) valid!: boolean;
    @Prop({default: false}) overlay!: boolean;

    Close(){
        this.$emit("fechar");
    }

    Salvar(){
        this.$emit("salvar");
    }
}
</script>
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/home'},
  { path: '/login', name: 'Login', component: () => import('../views/shared/Login.vue') },
  { path: '/home', name: 'Home', component: () => import('../views/shared/Home.vue') },
  { path: '/assunto', name: 'Assunto', component: () => import('../views/geral/assunto/Lista.vue') },
  { path: '/usuario', name: 'Usuario', component: () => import('../views/geral/usuario/Lista.vue') },
  { path: '/veiculo', name: 'Veiculo', component: () => import('../views/geral/veiculo/Lista.vue') },
  { path: '/cadastro/cliente', name: 'Cliente', component: () => import('../views/geral/cliente/Lista.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

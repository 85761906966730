import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared/";

export class UsuarioService extends Service{
    
    constructor(){
        super('v1/usuario');
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
            q: q
            },
            headers: httpHeader.headers
        });
    }

    async AlterarSenha(senhaAtual: string, senhaNova: string){
        const senhas: {senhaAtual: string, senhaNova: string} = {
            senhaAtual,
            senhaNova
        };        
        return await http.post(`${this.GetNomeControle()}/AlterarSenha`, senhas);
    }

    async ResetarSenha(id: number){
        return await http.post(`${this.GetNomeControle()}/${id}/ResetarSenha`);
    }

    async Autorizar(id: number, clienteId: number){
        return await http.post(`${this.GetNomeControle()}/${id}/Autorizar`, undefined, {
            params:{
                clienteId
            }
        });
    }

    async Desativar(id: number){
        return await http.post(`${this.GetNomeControle()}/${id}/Desativar`);
    }

    public MudarTema(id: number){    
        return http.put(`${this.GetNomeControle()}/${id}/MudarTema`, null);
    }
}
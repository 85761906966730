<template>
    <cadastro-page :dialog="dialog" :titulo="item.id > 0 ? 'Editar Assunto' : 'Criar Assunto'" :valid="valid" :overlay="loading" @fechar="Close()" @salvar="Salvar()">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card flat>
                <v-card-text class="mt-2">
                    <v-row dense>
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="item.nome" label="Nome" :rules="fieldRules" :counter="60" maxlength="60" dense outlined/>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12" class="mb-2">
                            <h2 style="color: black;">Termos</h2>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <v-row dense>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="termo.nome" label="Nome" dense outlined/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="3" style="display: flex; align-items: center; justify-content: flex-end;">
                            <v-btn class="btn btn-primary" @click="AdicionarTermo()">
                                <v-icon>mdi-plus</v-icon>
                                <span>Adicionar</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                    <v-divider class="my-2"/>
                    
                    <v-data-table :headers="termoHeaders" :items="item.termos" :items-per-page="-1" hide-default-footer>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-menu offset-y>
                                <template v-slot:activator="{on}">
                                    <v-btn small text v-on="on" color="primary">
                                        Ações
                                        <v-icon>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list class="pa-0">
                                    <v-list-item @click="ExcluirTermo(item)" dense>
                                        <v-list-item-title>
                                            <span>Excluir</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-divider/>
                                </v-list>
                            </v-menu>
                        </template>

                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-form>
    </cadastro-page>
</template>

<script lang="ts">
import { Assunto, AssuntoTermo, CrudBase } from '@/core/models';
import { AssuntoService } from '@/core/services';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { createPatch } from 'rfc6902';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class CadastroCliente extends CrudBase{
    @Prop() private value!: string;
    @Prop() item!: Assunto;

    itemOriginal!: Assunto;
    service: AssuntoService = new AssuntoService();
    $refs!: {
        form: HTMLFormElement
    }

    termo: AssuntoTermo = new AssuntoTermo();
    termoHeaders:any[] = [
        { text: '', value:'actions', sortable: false, class: 'action'},
        { text: 'Nome', value: 'nome'},
    ];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = JSON.parse(JSON.stringify(this.item));
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    Carregar(){
        
    }

    AdicionarTermo(){
        if(this.item.termos.find(x => x.nome == this.termo.nome)){
            AlertSimple("Aviso!", "Já existe um termo cadastrado!", "warning")
            return;
        }
        this.termo.assuntoId = this.item.id;
        this.item.termos.push(this.termo);
        this.termo = new AssuntoTermo();
    }

    ExcluirTermo(item: AssuntoTermo){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.termos.indexOf(item);
                context.item.termos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = createPatch(this.itemOriginal, this.item);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
</script>

<style lang="scss">
    .v-card{
        border-radius: 5px !important;
    }
</style>
import { PageBase } from "./PageBase";

export class CrudBase extends PageBase {

    public valid: boolean = true;
    public dialog: boolean = false;
    public tabActive: any = {};

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }
    
}